import "./App.css";
import React,{useEffect} from 'react'
import OneSignal from 'react-onesignal';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Locations from "./components/Locations";
import Laundry from "./components/Laundry";
import Application from './components/Application'
import Festive from "./components/Festive";
import Jetwash from "./components/Jetwash";

function App() {
 
  useEffect(() => {
    OneSignal.init({
      appId: "90e896c0-ceb5-4e1b-8b77-a1b9a5b27958"
    });
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/locations" element={<Locations />} />
          <Route path="/laundry" element={<Laundry />} />
          <Route path="/apply" element={<Application />} />
          <Route path="/christmas" element={<Festive />} />
          <Route path="/jetwash" element={<Jetwash/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
